import React, { Component } from 'react';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";


// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import CardBody from "../Card/CardBody.js";
import {Bar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import {Doughnut} from 'react-chartjs-2';

import swal from 'sweetalert';
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);


    
class CompClientesHoje extends Component {
    intervalID;


    state = {
        data: {
            idUnidOPe: '',
            unidOpe: '',
        },
        CHResumo: {
            qtdeAcessos: 0,
            qtdeVendas: 0,
            valorVendido: 0
        },
        CHConciliacao: {
            qtdeLotes: 0,
            totalRecebido: 0,
            totalConciliado: 0,
            taxaMedia: 0,
        },
        Carregando:true,
        TabelaConcilia: [],
        TabelaTempo: []
    }




    async hideAlert () {
        console.log('hide')
      };      
          
     
      tick() {
        this.setState(state => ({
          seconds: state.seconds + 1
        }));
        this.Recarrega();
      }

      checkPassword = async (senha) => {
        // const userPassword = prompt("Por favor, insira a senha:"); // Solicita a senha
        console.log("Iniciando checkPassword com senha:", senha); 
         try {
           // Faz uma chamada assíncrona para a API de validação
           const response = await auth.get('lukos/ValidaSenhahoje', {
             headers: {
               snha: senha // A senha inserida pelo usuário
             }
           });
           console.log('Resposta da API:', response.status);
         //   // Verifica o status da resposta
           if (response.status === 200) {
             return true; // Senha correta
           } 
             else {
                 console.log("Status diferente de 200");
             throw new Error("Senha incorreta"); // Força o erro se o status não for 200
           }
         } catch (error) {
         //   // Tratar erros de requisição ou validação
           console.error('Erro na validação da senha:', error); // Log do erro
            alert("Senha incorreta. Você será redirecionado.");
           window.location.href = "https://www.lukos.com.br"; // Redireciona para outra página
           return false;
         }
       };
      
    async componentDidMount() {

           
              this.getChResumo();
              this.getCHConciliacao();
              this.getDadosTabelaConcilia();
            //  await this.ChamadoPorDia();
            //  await this.HorasPorDia();
            //  await this.getTipoChamado();
            //  await this.getDadosTabela();
               this.getDadosTabelaTempo();
    //        await this.ChamadomesHora();
             this.interval = setInterval(() => this.tick(), 60000);
    }

    componentWillUnmount() {

          clearInterval(this.interval);
      }

        async getChResumo() {
            const response = await auth.get('/clienteshoje/resumo', {
            });
            this.setState({ CHResumo: response.data });
            console.log(response);
        }

        async getCHConciliacao(){
            const response =  await auth.get('/clienteshoje/chConciliacao', {
            });
            this.setState({ CHConciliacao: response.data });
            console.log(response);
        }              

        
        async ChamadoPorDia () {
            await auth.get('/chamadosMes/pordia', {
            }).then( res => {
                this.setState({ dataline :  {
                    labels: res.data.chamadopordia[0].labels,
                    datasets: res.data.chamadopordia[1].datasets,
                    options: res.data.chamadopordia[2].options
                                            } 
                                        })
              }
            );
            
        };  
        
        async HorasPorDia () {
            console.log('horas dia');
            await auth.get('/chamadosMes/HorasPorDia', {
            }).then( res => {
                console.log(res.data);
                this.setState({ datalineHoras :  {
                    labels: res.data.hosraspordia[0].labels,
                    datasets: res.data.hosraspordia[1].datasets,
                    options: res.data.hosraspordia[2].options
                                            } 
                                        })
              }
            );
            
        };         
        async getTipoChamado(){
            await auth.get('/chamadosMes/portipo', {
            }).then( res => {
                                this.setState({ dataTipoChamado :  {
                                    labels: res.data.MesTipoChamado[0].labels,
                                    datasets: res.data.MesTipoChamado[1].datasets,
                                    options: res.data.MesTipoChamado[2].options
                                                            } 
                                                        })                            
                            }
            )        
        }       
    
    async getDadosTabela() {
        await auth.get('/chamadosMes/CliMes', {
        }).then( res => {
            this.setState({ TabelaCliente: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    async getDadosTabelaTempo() {
        await auth.get('/clienteshoje/tabporcliente', {
        }).then( res => {
            this.setState({ TabelaTempo: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    async getDadosTabelaConcilia() {
        await auth.get('/clienteshoje/chtabconcilia', {
        }).then( res => {
            this.setState({ TabelaConcilia: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    async ChamadomesHora () {
        await auth.get('/chamadosMes/ChamadoMesHora', {
        }).then( res => {
            this.setState({ datalinehora :  {
                labels: res.data.chamadomeshora[0].labels,
                datasets: res.data.chamadomeshora[1].datasets,
                options: res.data.chamadomeshora[2].options
                                        } 
                                    })
          }
        );
        
    };   
    
        
    async Recarrega(){
        this.getChResumo();
        this.getDadosTabelaTempo();
        this.getCHConciliacao();
        this.getDadosTabelaConcilia();
    }


    render() {
        return (<div>
            <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>key</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Acessos</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.CHResumo.qtdeAcessos}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Quantidade de Acessos no sistema Lukos.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Qauntidade de Vendas</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.CHResumo.qtdeVendas}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Qtde total de vendas hoje
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Vendido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {'R$ ' +numberFormat2(this.state.CHResumo.valorVendido)} </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <LocalOffer />
                                Valor total vendido hoje.
                        </CardFooter>
                    </Card>
                </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color="rose" stats icon>
                            <CardIcon color="rose">
                                <Icon>show_chart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Conciliação Lotes</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {this.state.CHConciliacao.qtdeLotes} </h3>
                        </CardHeader>
                        <CardFooter stats>
                                Quantidade de Lotes processados.
                            <LocalOffer />

                        </CardFooter>
                    </Card>
                </GridItem>          
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>payment</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor Conciliado</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {'R$ ' +numberFormat2(this.state.CHConciliacao.totalConciliado)}
                                     </h3>
                        </CardHeader>
                        <CardFooter stats>
                                Valor total das vendas últimos 30 dias.
                            <LocalOffer />

                        </CardFooter>
                    </Card>
                </GridItem>          
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor Recebido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {'R$ ' +numberFormat2(this.state.CHConciliacao.totalRecebido)}
                                     </h3>
                        </CardHeader>
                        <CardFooter stats>
                                Valor total recebido
                            <LocalOffer />

                        </CardFooter>
                    </Card>
                </GridItem>          
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>percent</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Taxa Média</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {numberFormat2(this.state.CHConciliacao.taxaMedia)} </h3>
                        </CardHeader>
                        <CardFooter stats>
                                Taxa Média Total.
                            <LocalOffer />

                        </CardFooter>
                    </Card>
                </GridItem>                         
                </GridContainer>
                <GridContainer>
                {/*<GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por Dia  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.dataline}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="danger">
                            <h4 className="makeStyles-cardTitleWhite-87">Horas por Dia  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.datalineHoras}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>                   */}
                {/* <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por hora  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.datalinehora}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>                  
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">chamado por tipo </h4>
                        </CardHeader>
                        <CardBody>
                          <Doughnut data={this.state.dataTipoChamado} options={{legend: { display: false}}}/>
                        </CardBody>
                        <CardFooter>
                        chamado por tipo
                        </CardFooter>
                    </Card>
                </GridItem>      */}
                <GridItem xs={12} sm={5} md={5}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas Hoje por Cliente</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Un. Operacional", "Qtde Vendas", "Total Vendido", "Data ultima Venda"]}
                            tableData={this.state.TabelaTempo}
                        />
                        </CardBody>
                        <CardFooter>
                            Vendas hoje por unidade operacional.
                        </CardFooter>
                    </Card>
                </GridItem>                                  
                <GridItem xs={12} sm={7} md={7}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Conciliação por Adiquirente</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Conciliador", "Qtde Lotes", "Total Vendido", "Recebido", "Taxa Débito", "Taxa Crédito", "Taxa Pix"]}
                            tableData={this.state.TabelaConcilia}
                        />
                        </CardBody>
                        <CardFooter>
                            Conciliação dos últimso 30 dias.
                        </CardFooter>
                    </Card>
                </GridItem>                                                                             
                {/* <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por cliente</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Posição", "Banco de dados", "Chamados"]}
                            tableData={this.state.TabelaCliente}
                        />
                        </CardBody>
                        <CardFooter>
                        Pagamento de titulos por Categoria
                        </CardFooter>
                    </Card>
                </GridItem>                                                                                               */}
            </GridContainer>
        </div>
        )
        }
}


export default CompClientesHoje;
