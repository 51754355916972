import React, { Component } from "react";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import CustomInput from "../CustomInput/CustomInput.js";
import Button from "../CustomButtons/Button.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Contacts from "@material-ui/icons/Contacts";
import DateRange from "@material-ui/icons/DateRange";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import Swal from 'sweetalert2';
import auth from '../../services/auth';


import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { ContactSupportOutlined, RepeatOneSharp } from "@material-ui/icons";

class EditarPlaca extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idChamado: 0,
      nota: 0,
      dataFinalizacao: "",
      edtVeiculo: " ",
      login: ""
    };

    this.handleChangeGenero = this.handleChangeGenero.bind(this);
    this.SalvaEditplaca = this.SalvaEditplaca.bind(this);
    this.SalvaEditVeiculo = this.SalvaEditVeiculo.bind(this);
    this.SalvaEditMotorista = this.SalvaEditMotorista.bind(this);
    this.SalvaEditSenha = this.SalvaEditSenha.bind(this);
    this.SalvaLimiteR = this.SalvaLimiteR.bind(this);
    this.SalvaLitros = this.SalvaLitros.bind(this);
    this.SalvaEditAtivo = this.SalvaEditAtivo.bind(this);
  }

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const chavechamado = queryParams.get('chavechamado');
    await this.DadosPlaca(chavechamado);
    console.log(chavechamado); // Aqui você pode fazer o que precisar com a chave
  }

  async DadosPlaca(vchave) {
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.get("/chamados/LocChamado", {
      headers: {
        token: vchave
      },
    }).then(response => {
      this.setState({ idChamado: response.data.idChamado });
      this.setState({ login: response.data.login });
      this.setState({ dataFinalizacao: response.data.dataFinalizacao });
      // this.setState({ edtPlaca: response.data.placa });
      // this.setState({ edtMotorista: response.data.motorista });
      // this.setState({ edtVeiculo: response.data.veiculo });
      // this.setState({ edtSenha: response.data.senha });

      // this.setState({ edtLimiteR: response.data.limiteValor });
      // this.setState({ edtUsadoR: response.data.limiteUsado });
      // this.setState({ edtLimiteDisp: response.data.limiteDisponivel });
      // this.setState({ edtLitros: response.data.limiteLitros });
      // this.setState({ edtLitrosUsados: response.data.litrosUsados });
      // this.setState({ edtLitrosDisp: response.data.litrosDisp });
      // this.setState({ edtAtivo: response.data.ativo })


      console.log(response.data);
    })
      .catch(error => {
        console.log(error);
      })
  }

  verifyLength(value, length) {
    if ((value.length = length)) {
      this.setState({ vPlaca: true });
    }
    this.setState({ vPlaca: false });
  }

  SalvaEditAtivo(event) {
    // if ((this.state.edtAtivo ===  true)) {
    //   console.log("Muda false");
    //   this.setState({ edtAtivo: false });
    // } else
    // {
    //   console.log("Muda True");
    // this.setState({ edtAtivo: true });
    // }
    console.log(event.target.value);
    this.setState({ edtAtivo: event.target.value });
  }


  SalvaEditplaca(event) {
    this.setState({ edtPlaca: event.target.value.toUpperCase() });
  }

  SalvaEditVeiculo(event) {
    this.setState({ edtVeiculo: event.target.value.toUpperCase() });
  }

  SalvaEditMotorista(event) {
    this.setState({ edtMotorista: event.target.value.toUpperCase() });
  }

  SalvaEditSenha(event) {
    this.setState({ edtSenha: event.target.value.toUpperCase() });
  }

  SalvaLimiteR(event) {
    this.setState({ edtLimiteR: event.target.value });
    this.setState({ edtLimiteDisp: event.target.value - this.state.edtUsadoR })
  }

  SalvaLitros(event) {
    this.setState({ edtLitros: event.target.value });
    this.setState({ edtLitrosDisp: event.target.value - this.state.edtLitrosUsados });
  }

  handleChangeGenero(event) {
    console.log(event.target.value);
    this.setState({ edtCentro: event.target.value });
  }

  async getClientIP() {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  }

  async EnviaDadosPlaca() {
    const clientIP = await this.getClientIP();
    try {
      const res = await auth.get("/chamados/AvaliaChamado", {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          idChamado: this.state.idChamado,
          nota: this.state.edtAtivo,
          obs: encodeURIComponent(this.state.edtVeiculo),
          'X-Client-IP': clientIP

        },
      })
      console.log(res);
      if (res.status === 200) {
        Swal.fire(
          'Obrigado pela avaliação!',
          'Sua avaliação nos ajuda a melhorar nosso atendimento.',
          'success'
        ).then(() => {
          // Redireciona para o site da empresa após o alerta
          window.location.href = 'https://www.lukos.com.br';
        });
      }

    } catch (error) {
    } finally {
      // Swal.close() movido para aqui, para garantir que seja chamado após a execução de todos os casos
    }
  };


  teste() {
    this.EnviaDadosPlaca();
  }

  opensweetalert() {

    Swal.fire({
      title: 'Confirma avaliação?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: `Não`,
      confirmButtonColor: '#578F3D',
      cancelButtonColor: '#d33',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.teste();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async voltar() {
    console.log('Voltar');
    window.location.href = '/admin/Frota/';

  }

  async OpcaoCentro() {
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.get("/faturaweb/opcaocentrocusto", {
      headers: {
        token: chave,
      },
    }).then(res => {
      console.log(res.data);
      this.setState({ edtCentroCusto: res.data })
    })
      .catch(error => {
        console.log(error);
        Swal.fire('Sem  Conexão, tente novamente em alguns minutos.', '', 'error');
      })
  }



  render() {
    return (
      <div>
        <h1>
          {/* Editar Placa + {this.state.idPlaca} + {this.state.idCentroCusto}{" "} */}
        </h1>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 style={{ color: "black" }}>Avaliação de chamado</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4 style={{ color: "black", marginRight: "8px" }}>Chamado Nº:</h4>
                        <h4 style={{ color: "red" }}>{this.state.idChamado} </h4>
                      </div>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4 style={{ color: "black", marginRight: "8px" }}>Atendido por:</h4>
                        <h4 style={{ color: "red" }}>{this.state.login} </h4>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4 style={{ color: "black", marginRight: "8px" }}>Finalizado em:</h4>
                        <h4 style={{ color: "red" }}>{this.state.dataFinalizacao}</h4>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <h6>Avaliação</h6>
                      <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              id="vAtivo"
                              name="vAtivo"
                              type="radio"
                              checked={this.state.edtAtivo === "1"}
                              onChange={this.SalvaEditAtivo}
                              value={"1"}
                            />
                            <span className="form-check-sign" />
                            Péssimo
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              id="vAtivo"
                              name="vAtivo"
                              type="radio"
                              checked={this.state.edtAtivo === "2"}
                              onChange={this.SalvaEditAtivo}
                              value={"2"}
                            />
                            <span className="form-check-sign" />
                            Ruim
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              id="vAtivo"
                              name="vAtivo"
                              type="radio"
                              checked={this.state.edtAtivo === "3"}
                              onChange={this.SalvaEditAtivo}
                              value={"3"}
                            />
                            <span className="form-check-sign" />
                            Neutro
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              id="vAtivo"
                              name="vAtivo"
                              type="radio"
                              checked={this.state.edtAtivo === "4"}
                              onChange={this.SalvaEditAtivo}
                              value={"4"}
                            />
                            <span className="form-check-sign" />
                            Bom
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              id="vAtivo"
                              name="vAtivo"
                              type="radio"
                              checked={this.state.edtAtivo === "5"}
                              onChange={this.SalvaEditAtivo}
                              value={"5"}
                            />
                            <span className="form-check-sign" />
                            Ótimo
                          </Label>
                        </FormGroup>
                      </div>
                    </GridItem>


                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    id="edtVeiculo"
                    name="edtVeiculo"
                    labelText="Considerações"
                    style={{ width: "100%" }}
                    onChange={this.SalvaEditVeiculo}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        this.SalvaEditVeiculo(event);
                      },
                      value: this.state.edtVeiculo,
                      multiline: true, // Permite várias linhas
                      rows: 5, // Define a altura do textarea para 5 linhas
                      type: "text",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="success" className="marginLeft" onClick={() => this.opensweetalert()}>
                        Finalizar Avaliação
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>

              </CardBody>
              <CardFooter stats>
                <DateRange />
                Avaliação de atendimento
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default EditarPlaca;
