import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from './history.js';

import AuthLayout from 'layouts/Auth.js';
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import Login from './pages/login';
import Dashboard from './views/Dashboard/DashLukos';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";


ReactDOM.render(
  <Router history={history}>
    <Switch>
      {/* <Route path="/login" component={Login} /> */}
      {/* <Route exact path='/admin/detalhecaixa/:id' component={DetalhesCaixa} /> */}
      <Route path="/rtl" component={RtlLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Route path="/admin/dashboard" component={Dashboard}/>
      <Redirect from="/" to="/admin/dashboard" /> 
      <Route component={Dashboard} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
