import React, { Component } from 'react';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";


// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import CardBody from "../Card/CardBody.js";
import {Bar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import {Doughnut} from 'react-chartjs-2';

import swal from 'sweetalert';
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);


    
class CompComparativoVendas extends Component {
    intervalID;


    state = {
        data: {
            idUnidOPe: '',
            unidOpe: '',
        },
        ChamadosMes: {
            chamadosEmAberto: 0,
            mediadiaria: 0,
            totalChamados: 0,
            mediaminutos: 0
        },
        Carregando:true,
        TabelaCliente: [],
        TabelaTempo: []
    }




    async hideAlert () {
        console.log('hide')
      };      
          
     
      tick() {
        this.setState(state => ({
          seconds: state.seconds + 1
        }));
        this.Recarrega();
      }
      
// Função para verificar a senha
checkPassword = async (senha) => {
    // const userPassword = prompt("Por favor, insira a senha:"); // Solicita a senha
    console.log("Iniciando checkPassword com senha:", senha); 
     try {
       // Faz uma chamada assíncrona para a API de validação
       const response = await auth.get('lukos/ValidaSenhahoje', {
         headers: {
           snha: senha // A senha inserida pelo usuário
         }
       });
       console.log('Resposta da API:', response.status);
     //   // Verifica o status da resposta
       if (response.status === 200) {
         return true; // Senha correta
       } 
         else {
             console.log("Status diferente de 200");
         throw new Error("Senha incorreta"); // Força o erro se o status não for 200
       }
     } catch (error) {
     //   // Tratar erros de requisição ou validação
       console.error('Erro na validação da senha:', error); // Log do erro
        alert("Senha incorreta. Você será redirecionado.");
       window.location.href = "https://www.lukos.com.br"; // Redireciona para outra página
       return false;
     }
   };
 

    async componentDidMount() {


             await this.getChamadosMes();
             await this.getGrafFinal();
             await this.ChamadoPorDia();
             await this.HorasPorDia();
             await this.getTipoChamado();
             await this.getDadosTabela();
              await this.getDadosTabelaTempo();
            await this.ChamadomesHora();
             this.interval = setInterval(() => this.tick(), 600000);
    }

    componentWillUnmount() {

          clearInterval(this.interval);
      }

    async getChamadosMes() {
            const response = await auth.get('/chamadosMes/DashBoard', {
            });
            this.setState({ ChamadosMes: response.data });
            console.log(response);
        }

        async getGrafFinal(){
            await auth.get('/chamadosMEs/GrafFinal', {
            }).then( res => {
                                console.log(res.data)
                                this.setState({ datalbar :  {
                                    labels: res.data.GrafMesFinalizado[0].labels,
                                    datasets: res.data.GrafMesFinalizado[1].datasets,
                                    options: res.data.GrafMesFinalizado[2].options
                                                            } 
                                                        })                            
                            }
            )        
        }              

        
        async ChamadoPorDia () {
            await auth.get('/chamadosMes/pordia', {
            }).then( res => {
                this.setState({ dataline :  {
                    labels: res.data.chamadopordia[0].labels,
                    datasets: res.data.chamadopordia[1].datasets,
                    options: res.data.chamadopordia[2].options
                                            } 
                                        })
              }
            );
            
        };  
        
        async HorasPorDia () {
            console.log('horas dia');
            await auth.get('/chamadosMes/HorasPorDia', {
            }).then( res => {
                console.log(res.data);
                this.setState({ datalineHoras :  {
                    labels: res.data.hosraspordia[0].labels,
                    datasets: res.data.hosraspordia[1].datasets,
                    options: res.data.hosraspordia[2].options
                                            } 
                                        })
              }
            );
            
        };         
        async getTipoChamado(){
            await auth.get('/chamadosMes/portipo', {
            }).then( res => {
                                this.setState({ dataTipoChamado :  {
                                    labels: res.data.MesTipoChamado[0].labels,
                                    datasets: res.data.MesTipoChamado[1].datasets,
                                    options: res.data.MesTipoChamado[2].options
                                                            } 
                                                        })                            
                            }
            )        
        }       
    
    async getDadosTabela() {
        await auth.get('/chamadosMes/CliMes', {
        }).then( res => {
            this.setState({ TabelaCliente: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    async getDadosTabelaTempo() {
        await auth.get('/chamadosMes/MediaTempo', {
        }).then( res => {
            this.setState({ TabelaTempo: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    async ChamadomesHora () {
        await auth.get('/chamadosMes/ChamadoMesHora', {
        }).then( res => {
            this.setState({ datalinehora :  {
                labels: res.data.chamadomeshora[0].labels,
                datasets: res.data.chamadomeshora[1].datasets,
                options: res.data.chamadomeshora[2].options
                                        } 
                                    })
          }
        );
        
    };   
    
        
    async Recarrega(){
        window.location.reload(false);
    }


    render() {
        return (<div>
            <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Média por dia</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.ChamadosMes.mediadiaria}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Media diária do Mês.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Tempo médio do chamado.</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.ChamadosMes.mediaminutos}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Tempo Médio em minutos para solução de chamados.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>show_chart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Finalizados</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {this.state.ChamadosMes.totalChamados} </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <LocalOffer />
                                Total de Chamados do Mês.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados Finalizados  </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.datalbar} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Chamados finalizados nos ultimos 30 dias.
                        </CardFooter>
                    </Card>
                </GridItem>                            
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por Dia  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.dataline}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="danger">
                            <h4 className="makeStyles-cardTitleWhite-87">Horas por Dia  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.datalineHoras}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>                  
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por hora  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.datalinehora}     options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                              Chamados abertos por hora
                        </CardFooter>
                    </Card>
                </GridItem>                  
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">chamado por tipo </h4>
                        </CardHeader>
                        <CardBody>
                          <Doughnut data={this.state.dataTipoChamado} options={{legend: { display: false}}}/>
                        </CardBody>
                        <CardFooter>
                        chamado por tipo
                        </CardFooter>
                    </Card>
                </GridItem>     
                <GridItem xs={12} sm={6} md={5}>
                    <Card>
                        <CardHeader color="danger">
                            <h4 className="makeStyles-cardTitleWhite-87">Tempo de atendimento</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Chamados", "Tempo Atendimento", "Média", "Funcionário"]}
                            tableData={this.state.TabelaTempo}
                        />
                        </CardBody>
                        <CardFooter>
                            Média de tempo de atendimento.
                        </CardFooter>
                    </Card>
                </GridItem>                                                                                              
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Chamados por cliente</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Posição", "Banco de dados", "Chamados"]}
                            tableData={this.state.TabelaCliente}
                        />
                        </CardBody>
                        <CardFooter>
                        Pagamento de titulos por Categoria
                        </CardFooter>
                    </Card>
                </GridItem>                                                                                              
            </GridContainer>
        </div>
        )
        }
}


export default CompComparativoVendas;
