import DashboardIcon from "@material-ui/icons/Dashboard";
import DashboardLukos from "./views/Dashboard/DashLukos.js";
import ComparativoVendas from "views/Dashboard/ComparativoVendas";
import ClientesHoje from "views/Dashboard/ClientesHoje.js";

 
var dashRoutes = 
[ 
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "support_agent",
  //  component: Dashboard,
    component: DashboardLukos,
    layout: "/admin"
   },
  {
    path: "/ComparativoVendas",
    name: "Chamados do Mês",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: ComparativoVendas,
    layout: "/admin"
  } ,
  {
    path: "/ClientesHoje",
    name: "Clientes Hoje",
    rtlName: "قائمة الجدول",
    icon: "shopping_cart",
    component: ClientesHoje,
    layout: "/admin"
  }  
 
];

export default dashRoutes;
