import React from 'react';
import { Redirect } from "react-router-dom";
import CompDashBoard from '../../components/DashBoard/compdashboard';
import SweetAlert from "views/Components/SweetAlert.js";


export default function DashboardLukos() {
      // const isLogged = !!localStorage.getItem('lukos-token');
      
      // if (isLogged === false) {
      //   return(<Redirect to="/login" />)
      // }
      // else
      // {
          return (        
                    <div>
                      <CompDashBoard />
                  </div>
                  );
  // };
}
