import React from 'react';

import { Redirect } from "react-router-dom";
import CompComparativoVendas from '../../components/DashBoard/compcomparativovendas';


export default function ComparativoVendas() {
        return (        
                    <div>
                      <CompComparativoVendas />
                  </div>
                  );
 };

